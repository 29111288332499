import React, { useEffect, useState } from 'react'

import { isLoggedIn, login as loginApi, logout as logoutApi, createUser as registerApi } from '@api'
import { toast } from '@components'
import { useQuery } from '@hooks'
import { AuthenticationContextType, AuthenticationProviderProps, AuthenticationStateType } from '@types'

import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

const initState: AuthenticationStateType = {
  authenticated: false,
  user: null,
}

export const AuthenticationContext = React.createContext<AuthenticationContextType>({
  ...initState,
  login: () => {},
  register: () => {},
  logout: () => {},
})

export const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({ children }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const query = useQuery()
  const [authState, setAuthState] = useState<AuthenticationStateType>(initState)

  const redirect = () => {
    navigate(query.get('redirect_uri') || '/')
  }

  const login = (email: string, password: string) => {
    loginApi(email, password)
      .then((data) => {
        setAuthState({ authenticated: true, user: data || '' })
        redirect()
      })
      .catch(() => {
        setAuthState({ authenticated: false, user: null })
        toast(t('Could not login'), t('Please check your credentials'), { type: 'error' })
      })
  }

  const register = async (email: string, password: string, confirmation: string) => {
    registerApi(email, password, confirmation)
      .then((data) => {
        setAuthState({ authenticated: true, user: data || '' })
        redirect()
        toast(t('Account created'), '', { type: 'success' })
      })
      .catch((error) => {
        toast(t('Could not register'), t(error.response.data), { type: 'error' })
      })
  }

  const logout = () => {
    logoutApi()
      .then(() => {
        setAuthState(initState)
        navigate(`/login`)
      })
      .catch(() => {
        toast(t('Could not logout'), t('Please try again'), { type: 'error' })
      })
  }

  useEffect(() => {
    isLoggedIn()
      .then((data) => {
        setAuthState({ user: data, authenticated: true })
        if (location.pathname === '/login') {
          redirect()
        }
      })
      .catch(() => {
        setAuthState({ user: null, authenticated: false })

        if (!location.pathname.match(/^(\/|\/login|\/register)$/)) {
          navigate(`/login?redirect_uri=${encodeURI(location.pathname)}`)
        }
      })
  }, [])

  useEffect(() => {
    if (location.pathname === '/logout') {
      logout()
    }
  }, [location])

  return (
    <AuthenticationContext.Provider value={{ ...authState, login, logout, register }}>
      {children}
    </AuthenticationContext.Provider>
  )
}
