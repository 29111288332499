import React from 'react'

import openGraphImage from '@assets/images/mySetup.png'
import illustration from '@assets/images/presentation.svg'
import { Button, ButtonVariant } from '@components'
import { useAuth } from '@hooks'

import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const Home: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useAuth()

  return (
    <div className="max-w-7xl mx-auto flex flex-col content-center lg:flex-row justify-between p-5 gap-6 my-auto">
      <Helmet>
        <title>mySetup - {t('Manage your setup')}</title>
        <meta name="description" content={t('My setup presentation')} />
        <meta property="og:title" content={`mySetup - ${t('Manage your setup')}`} />
        <meta property="og:description" content={t('My setup presentation')} />
        <meta property="og:image" content={openGraphImage} />
        <meta property="og:url" content="https://setup.lucasstbnr.ovh" />
        <meta property="og:type" content="website" />
        <link rel="alternate" hrefLang="en-US" href="https://setup.lucasstbnr.ovh" />
        <link rel="alternate" hrefLang="fr-FR" href="https://setup.lucasstbnr.ovh" />
      </Helmet>
      <main className="w-full lg:w-1/2 mt-10 mx-auto px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <h1 className="text-4xl tracking-tight font-bold text-slate-800 sm:text-5xl sm:tracking-tight md:text-6xl md:tracking-tight">
          mySetup
        </h1>
        <p className="mt-3 text-base text-slate-500 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
          {t('My setup presentation')}
        </p>
        {user ? (
          <Link to="setup">
            <Button className="mt-14" variant={ButtonVariant.PRIMARY}>
              {t('Go to my setup')}
            </Button>
          </Link>
        ) : (
          <Link to="login">
            <Button className="mt-14" variant={ButtonVariant.PRIMARY}>
              {t('Sign in')} / {t('Sign up')}
            </Button>
          </Link>
        )}
      </main>
      <img className="w-full lg:w-1/2" src={illustration} fetchpriority="high" alt="chart illustration" />
    </div>
  )
}
