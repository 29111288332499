import React from 'react'

import illustration from '@assets/images/not_found.svg'

import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

export const NotFound: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="w-full flex flex-col items-center justify-center p-10 gap-6">
      <Helmet>
        <title>{t('Error')} - mySetup</title>
        <meta property="og:title" content={`${t('Error')} - mySetup`} />
        <meta property="og:url" content="https://setup.lucasstbnr.ovh" />
        <link rel="canonical" href="https://setup.lucasstbnr.ovh" />
      </Helmet>
      <img className="max-h-96" src={illustration} alt="page not found illustration" />
      <h1>{t("This page doesn't exist")}</h1>
    </div>
  )
}
