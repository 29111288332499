import { IItem } from '@types'

import axios from 'axios'

export const getItems = async (): Promise<IItem[]> => {
  const response = await axios.get('/item')
  return response.data
}

export const getItem = async (id: string): Promise<IItem> => {
  const response = await axios.get(`/item/${id}`)
  return response.data
}

export const createItem = async (item: FormData): Promise<IItem> => {
  const response = await axios.post('/item', item)
  return response.data
}

export const updateItem = async (id: string, item: FormData): Promise<IItem[]> => {
  const response = await axios.put(`/item/${id}`, item)
  return response.data
}

export const deleteItem = async (id: string): Promise<IItem> => {
  const response = await axios.delete(`/item/${id}`)
  return response.data
}
