import { StatsGlobal } from '@pages'
import { IRoom } from '@types'

import axios from 'axios'

type StatsType = {
  global: StatsGlobal
  rooms: Record<string, IRoom>
  years: Record<string, number>
}

export const getRooms = async (): Promise<IRoom[]> => {
  try {
    const response = await axios.get('/room')
    return response.data
  } catch (error: any) {
    return error.response.data
  }
}

export const getRoom = async (id: string): Promise<IRoom> => {
  const response = await axios.get(`/room/${id}`)
  return response.data
}

export const getRoomStats = async (): Promise<StatsType> => {
  const response = await axios.get(`/room/stats`)
  return response.data
}

export const createRoom = async (room: { name: string }): Promise<IRoom> => {
  const response = await axios.post('/room', room)
  return response.data
}

export const updateRoom = async (id: string, room: IRoom): Promise<IRoom[]> => {
  const response = await axios.put(`/room/${id}`, room)
  return response.data
}

export const deleteRoom = async (id: string): Promise<IRoom> => {
  const response = await axios.delete(`/room/${id}`)
  return response.data
}
