import React from 'react'

import { ToastContainerProps, ToastPosition } from '@components'

import { ToastContainer as ToastContainerComponent } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const ToastContainer: React.FC<ToastContainerProps> = ({
  position = ToastPosition.BOTTOM_RIGHT,
  hideProgressBar = true,
  autoClose = 5000,
  toastClassName = 'pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 p-0',
  bodyClassName = 'p-0 m-0',
  progressClassName,
  limit = 3,
  enableMultiContainer = true,
  icon = false,
  closeButton = false,
  ...props
}) => {
  return (
    <ToastContainerComponent
      position={position}
      hideProgressBar={hideProgressBar}
      autoClose={autoClose}
      toastClassName={toastClassName}
      bodyClassName={bodyClassName}
      progressClassName={progressClassName}
      limit={limit}
      enableMultiContainer={enableMultiContainer}
      icon={icon}
      closeButton={closeButton}
      {...props}
    />
  )
}
