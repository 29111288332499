import React, { useEffect, useState } from 'react'

import { isLoggedIn, updateUser } from '@api'
import { Button, ButtonVariant, Input, toast } from '@components'
import { IUser } from '@types'

import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

export const Account = () => {
  const { t } = useTranslation()

  const [user, setUser] = useState<IUser | undefined>(undefined)
  const [username, setUsername] = useState('')
  const [actualPassword, setActualPassword] = useState('')
  const [password, setPassword] = useState('')
  const [confirmation, setConfirmation] = useState('')

  useEffect(() => {
    isLoggedIn()
      .then((result) => {
        setUser(result)
      })
      .catch((error) => {
        toast(t('Oops'), t(error.response.data), { type: 'error' })
      })
  }, [])

  useEffect(() => {
    setUsername(user?.username as string)
  }, [user])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (user) {
      updateUser(user, user.username, actualPassword, password, confirmation)
        .then(() => {
          setActualPassword('')
          setPassword('')
          setConfirmation('')
          toast(t('User updated'), '', { type: 'success' })
        })
        .catch((error) => {
          toast(t('Could not get user info'), t(error.response.data), { type: 'error' })
        })
    } else {
      toast(t('You seem disconnected'), '', { type: 'error' })
    }
  }

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <Helmet>
        <title>{t('My account')} - mySetup</title>
        <meta property="og:title" content={`${t('My account')} - mySetup`} />
        <meta property="og:url" content="https://setup.lucasstbnr.ovh" />
        <link rel="canonical" href="https://setup.lucasstbnr.ovh" />
      </Helmet>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={onSubmit}>
            <Input
              id="username"
              name="username"
              type="text"
              label={t('Username')}
              onChange={(value) => setUsername(value as string)}
              value={username}
              isRequired
            />
            <Input
              id="actualPassword"
              name="actualPassword"
              type="password"
              label={t('Actual password')}
              onChange={(value) => setActualPassword(value as string)}
              value={actualPassword}
            />
            <Input
              id="password"
              name="password"
              type="password"
              label={t('Password')}
              onChange={(value) => setPassword(value as string)}
              value={password}
            />
            <Input
              id="confirmation"
              name="confirmation"
              type="password"
              label={t('Confirmation')}
              onChange={(value) => setConfirmation(value as string)}
              value={confirmation}
            />
            <div className="text-right">
              <Button type="submit" className="text-right" variant={ButtonVariant.PRIMARY}>
                {t('Submit')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
