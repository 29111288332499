import { IUser } from '@types'

import axios from 'axios'

export const createUser = async (username: string, password: string, confirmation: string): Promise<IUser> => {
  const response = await axios.post('/user', { username, password, confirmation })
  return response.data
}

export const updateUser = async (
  user: IUser,
  username: string,
  old_password: string,
  password: string,
  confirmation: string,
): Promise<IUser> => {
  const response = await axios.put(`/user/${user._id}`, {
    username,
    old_password,
    password,
    confirmation,
  })
  return response.data
}
