import { IUser } from '@types'

import axios from 'axios'

export const isLoggedIn = async (): Promise<IUser> => {
  const response = await axios.get('/authenticate')
  return response.data.user
}

export const login = async (username: string, password: string): Promise<IUser> => {
  const response = await axios.post('/authenticate', { username, password })
  return response.data
}

export const logout = async (): Promise<string> => {
  const response = await axios.delete('/authenticate')
  return response.data
}
