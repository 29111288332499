import React from 'react'

import { ToastOptions } from '@components'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/24/solid'

import { toast as toastify } from 'react-toastify'

export const toast = (title: string, message?: string, options?: ToastOptions) => {
  const { type, ...rest } = options || {}

  let icon: React.ReactNode
  switch (type) {
    case 'success':
      icon = <CheckCircleIcon className="h-6 w-6 text-emerald-500" />
      break
    case 'info':
      icon = <QuestionMarkCircleIcon className="h-6 w-6 text-teal-500" />
      break
    case 'warning':
      icon = <ExclamationTriangleIcon className="h-6 w-6 text-amber-400" />
      break
    case 'error':
      icon = <ExclamationCircleIcon className="h-6 w-6 text-red-600" />
      break
  }

  toastify(
    <div className="p-4">
      <div className="flex items-start">
        <div className="flex-shrink-0">{icon}</div>
        <div className="pt-0.5 ml-3 w-0 flex-1">
          <p className="text-sm font-medium text-gray-900">{title}</p>
          {message && <p className="mt-1 text-sm text-gray-500">{message}</p>}
        </div>
        <div className="ml-4 flex flex-shrink-0">
          <button
            type="button"
            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>,
    rest,
  )
}
