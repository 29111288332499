import { CategoryType } from '@types'

import axios from 'axios'

const getCategories = async (): Promise<CategoryType[]> => {
  const response = await axios.get('/category')
  return response.data
}

export { getCategories }
