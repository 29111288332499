import React from 'react'

import { Card, PageHeader } from '@components'
import { ChevronUpDownIcon, PencilIcon, PlusIcon } from '@heroicons/react/24/outline'

import { useTranslation } from 'react-i18next'

export const RoomSkeleton: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="my-auto">
      <PageHeader
        isLoading
        title="xxxxxxxxxxxx"
        actions={[
          { label: t('Add item'), icon: PlusIcon, isDisabled: true },
          { label: t('Edit room'), icon: PencilIcon, isDisabled: true },
          {
            isDisabled: true,
            label: t('Sort by name'),
            icon: ChevronUpDownIcon,
          },
        ]}
      />

      <div className="mx-auto max-w-6xl p-4 sm:p-6 lg:p-8 grid grid-cols-2 gap-5 lg:grid-cols-3">
        <Card>
          <p className="truncate text-sm font-medium text-gray-500 mb-0">{t('Item', { count: 42 })}</p>
          <p className="text-lg font-medium text-gray-900 mb-0 w-fit animate-pulse bg-slate-700 rounded-full text-transparent">
            xxxxxxx
          </p>
        </Card>
        <Card>
          <p className="truncate text-sm font-medium text-gray-500 mb-0">{t('Total')}</p>
          <p className="text-lg font-medium text-gray-900 mb-0 w-fit animate-pulse bg-slate-700 rounded-full text-transparent">
            {t('Money', { val: 42, minimumFractionDigits: 2 })}
          </p>
        </Card>
        <Card>
          <p className="truncate text-sm font-medium text-gray-500 mb-0">{t('Average')}</p>
          <p className="text-lg font-medium text-gray-900 mb-0 w-fit animate-pulse bg-slate-700 rounded-full text-transparent">
            {t('Money', { val: 42, minimumFractionDigits: 2 })}
          </p>
        </Card>
      </div>
    </div>
  )
}
